/*
    Slider se koristi za Template V4  unutar Slider Config-a u CMS-u
    Služi za slider komentara korisnika
*/
.slider-section {
    &.slider_v4 {
        padding-bottom: 20px;

        .slider-super-title {
            text-transform: none;

            h2 {
                font-weight: 300;
                font-size: 30px;

                strong {
                    font-weight: 700;
                }
            }
        }

        .comment-text {
            display: inline-block;
            text-align: center;

            p {
                font-family: font(sub);
                font-weight: 500;
                font-size: 14px;
                width: auto;
                max-width: 80%;
                display: inline-block;
                padding: 30px 0;
                color: color('text', 'light');
                line-height: 24px;

                @media(max-width: $media_md) {
                    max-width: 100%;
                    display: block;
                }
            }
        }

        .comment-quote-start {
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: top;
            height: 18px;
            width: 22px;

            @media(max-width: $media_md) {
                display: block;
                margin: 0 auto;
                vertical-align: middle;
            }
        }

        .comment-quote-end {
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: bottom;
            height: 18px;
            width: 22px;

            @media(max-width: $media_md) {
                display: block;
                margin: 0 auto;
                vertical-align: middle;
            }
        }

        .comment-author {
            font-family: font(sub);
            font-size: 14px;
            font-weight: 700;
            text-align: center;

            .comment-rating {
                display: block;
                font-style: italic;
                font-weight: 300;
                color: color('text', 'light');
            }
        }

        .comments_slider_init {
            .navi {
                top: unset !important;
                bottom: 0 !important;
                // margin-top: -25px;
                font-size: 24px;
                background: transparent !important;
                color: color('primary') !important;

                &.navi-prev {
                    left: 40% !important;
                }

                &.navi-next {
                    right: 40% !important;
                }

                @media (max-width: 1250px) {
                    &.navi-prev {
                        left: -20px !important;
                    }

                    &.navi-next {
                        right: -20px !important;
                    }
                }
            }
        }

    }
}