.custom-link-list-block {
    background: color('gray');
    padding: 50px 0 50px;
    border-bottom: 1px solid #dfdfdf;
    border-top: 1px solid #dfdfdf;
    @media (max-width: 720px){
        text-align: center;
    }

    .row {
        > div {
            h3,
            a {
                font-weight: 500;
            }

            h3 {
                font-size: 19px;
                color: #252629;
                font-weight: 600;
            }

            a {
                font-size: 15px;
                color: color('white');
                text-decoration: none;

                &:hover{
                    color: color('black');
                }
            }
        }
    }
}