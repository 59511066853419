.jobs-section {
    h1 {
        font-family: "Barlow", sans-serif;
        font-size: 25px;
        font-weight: 500;
        color: #7e8087;
    }

    .jobs-separator {
        margin-bottom: 0 !important;
    }

    .section-text {
        p {
            font-family: "Barlow", sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            padding-bottom: 1rem;
        }
    }
}